
import { Component, Prop, Vue } from 'vue-property-decorator';
import { gltfRenderer } from '@/components/util/gltfRenderInstance';

@Component({
  methods: {
    gltfRenderer() {
      return gltfRenderer;
    }
  },
  watch: {
    identifier(newValue: string, oldValue: string) {
      gltfRenderer.removeScene(oldValue);
    }
  }
})
export default class GLTFViewer extends Vue {
  @Prop({ required: true }) height!: number;
  @Prop({ required: true }) url!: string;
  @Prop({ required: true }) identifier!: string;

  mounted() {
    this.createScene(this.$refs.scene as HTMLElement);
  }

  beforeDestroy() {
    gltfRenderer.removeScene(this.identifier);
  }

  createScene(el: HTMLElement) {
    gltfRenderer.createScene(el, {
      identifier: this.identifier,
      gltfUrl: this.url
    });
  }
}
