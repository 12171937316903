import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

// Minimal ResizeObserver type declaration
type ResizeObserverCallback = (entries: ResizeObserverEntry[]) => void;
type ResizeObserverEntry = {
  target: Element;
  contentRect: DOMRectReadOnly;
};
declare class ResizeObserver {
  constructor(callback: ResizeObserverCallback);
  observe(target: Element): void;
  unobserve(target: Element): void;
  disconnect(): void;
}

export interface GLTFResource {
  identifier: string;
  gltfUrl: string;
}

class GLTFRenderer {
  private renderer: THREE.WebGLRenderer;
  private readonly canvas: HTMLCanvasElement;
  private readonly scenes: { [key: string]: THREE.Scene };
  private gltfLoader: GLTFLoader;
  private animationFrameId: number = 0;
  private resizeObserver: ResizeObserver;

  constructor() {
    this.canvas = document.createElement('canvas');
    this.gltfLoader = new GLTFLoader();
    this.gltfLoader.setWithCredentials(true);
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      powerPreference: 'high-performance'
    });
    this.renderer.setClearColor(0xffffff, 1);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.scenes = {};

    // Create resize observer to handle container size changes
    this.resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        const element = entry.target as HTMLElement;
        const scene = Object.values(this.scenes).find(s => s.userData.element === element);
        if (scene) {
          this.updateSceneSize(scene);
        }
      }
    });

    // Handle window resize and zoom
    window.addEventListener('resize', () => this.handleWindowResize());
  }

  private handleWindowResize() {
    // Update for all scenes
    Object.values(this.scenes).forEach(scene => {
      this.updateSceneSize(scene);
    });
  }
  private updateSceneSize(scene: THREE.Scene) {
    const element = scene.userData.element;

    const width = element.offsetWidth;
    const height = element.offsetHeight;
    const pixelRatio = Math.min(window.devicePixelRatio, 2); // Limit pixel ratio to avoid excessive resource usage.

    // Update canvas style dimensions
    const canvas = scene.userData.ctx.canvas;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    // Update actual pixel dimensions
    canvas.width = Math.floor(width * pixelRatio);
    canvas.height = Math.floor(height * pixelRatio);

    // Update camera
    const camera = scene.userData.camera;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();

    // Update renderer
    this.renderer.setSize(canvas.width, canvas.height, false);
    this.renderer.setPixelRatio(pixelRatio); // Set renderer's pixel ratio
  }

  private frameArea(
    sizeToFitOnScreen: number,
    boxSize: number,
    boxCenter: THREE.Vector3,
    camera: THREE.PerspectiveCamera
  ) {
    const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

    // Position camera directly above the box center
    camera.position.set(boxCenter.x, boxCenter.y + distance, boxCenter.z);

    camera.near = 0.0001;
    camera.far = 1000;
    camera.updateProjectionMatrix();
    camera.lookAt(boxCenter);
  }

  public createScene(sceneElement: HTMLElement | any, gltfResource: GLTFResource, isModal: boolean = false) {
    const identifier = isModal ? gltfResource.identifier + '-modal' : gltfResource.identifier;

    if (!(sceneElement instanceof HTMLElement)) {
      this.removeScene(identifier);
      return;
    }

    cancelAnimationFrame(this.animationFrameId);
    const scene = new THREE.Scene();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { alpha: false });
    if (!ctx) return;

    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';
    sceneElement.style.position = 'relative';
    sceneElement.appendChild(canvas);

    scene.userData.ctx = ctx;
    scene.userData.gltfResource = gltfResource;
    scene.userData.element = sceneElement;

    this.resizeObserver.observe(sceneElement);

    const camera = new THREE.PerspectiveCamera(50, 1, 0.0001, 1000);
    scene.userData.camera = camera;

    const controls = new OrbitControls(camera, canvas);
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.rotateSpeed = 0.5;
    controls.zoomSpeed = 1.2;
    scene.userData.controls = controls;

    this.gltfLoader.load(gltfResource.gltfUrl, gltf => {
      const geometry = gltf.scene;

      let box = new THREE.Box3().setFromObject(geometry);
      let boxSize = box.getSize(new THREE.Vector3()).length();
      const scale = (1.0 / boxSize) * 2;
      geometry.scale.set(scale, scale, scale);

      // Update bounding box after scaling
      box = new THREE.Box3().setFromObject(geometry);
      boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());

      this.frameArea(boxSize * 1.1, boxSize, boxCenter, camera);

      controls.maxDistance = boxSize * 10;
      controls.minDistance = boxSize * 0.01;
      controls.target.copy(boxCenter);
      controls.update();

      scene.userData.initialCameraPosition = camera.position.clone();
      scene.userData.initialCameraTarget = controls.target.clone();

      // Update materials for lines
      geometry.traverse((object: THREE.Object3D) => {
        if (object instanceof THREE.Line) {
          object.material = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 1
          });
        }
      });

      scene.add(geometry);
      this.updateSceneSize(scene);
    });

    // Lighting setup
    scene.add(new THREE.HemisphereLight(0xffffff, 0x000000, 0.8));

    const lights = [
      { position: new THREE.Vector3(-1, 2, 4) },
      { position: new THREE.Vector3(-1, 2, -4) },
      { position: new THREE.Vector3(5, 0, 4) }
    ];

    lights.forEach(config => {
      const light = new THREE.DirectionalLight(0xffffff, 1);
      light.position.copy(config.position);
      scene.add(light);
    });

    // Add reset camera button
    const resetIcon = document.createElement('i');
    resetIcon.className = 'mdi mdi-camera-retake-outline';
    Object.assign(resetIcon.style, {
      position: 'absolute',
      right: '10px',
      top: '10px',
      cursor: 'pointer',
      fontSize: '1.5em',
      zIndex: '1'
    });
    resetIcon.addEventListener('pointerdown', () => this.resetCamera(identifier));
    sceneElement.appendChild(resetIcon);

    this.scenes[identifier] = scene;
    this.updateSceneSize(scene);
    this.animationFrameId = requestAnimationFrame(() => this.animate());
  }
  public render() {
    this.renderer.setClearColor(0xf6f6f6, 1); // Ensure no transparency
    this.renderer.setScissorTest(true);

    Object.values(this.scenes).forEach(scene => {
      const element = scene.userData.element;

      // Skip rendering if the element is not visible or has no size
      const width = element.offsetWidth;
      const height = element.offsetHeight;
      if (width === 0 || height === 0 || !this.isElementVisible(element)) {
        return;
      }

      const camera = scene.userData.camera;
      const canvas = scene.userData.ctx.canvas;

      // Update camera aspect ratio and projection matrix
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      // Update controls if present
      if (scene.userData.controls) {
        scene.userData.controls.update();
      }

      // Render scene using updated dimensions
      this.renderer.setViewport(0, 0, canvas.width, canvas.height);
      this.renderer.setScissor(0, 0, canvas.width, canvas.height);
      this.renderer.render(scene, camera);

      // Copy rendered content to the 2D canvas
      if (this.renderer.domElement.width > 0 && this.renderer.domElement.height > 0) {
        scene.userData.ctx.globalCompositeOperation = 'copy';
        scene.userData.ctx.drawImage(
          this.renderer.domElement,
          0,
          0,
          this.renderer.domElement.width,
          this.renderer.domElement.height,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
    });
  }

  private isElementVisible(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight || document.documentElement.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth;

    // Check if element is fully outside the viewport
    return !(
      (
        rect.bottom < 0 || // Above viewport
        rect.top > windowHeight || // Below viewport
        rect.right < 0 || // Left of viewport
        rect.left > windowWidth
      ) // Right of viewport
    );
  }
  public removeScene(identifier: string) {
    if (identifier in this.scenes) {
      const scene = this.scenes[identifier];

      // Stop observing the element
      this.resizeObserver.unobserve(scene.userData.element);

      // Remove all children from the scene's element
      const element = scene.userData.element;
      while (element.firstChild) {
        element.removeChild(element.firstChild);
      }

      // Dispose of materials, geometries and textures
      scene.traverse(object => {
        if (object instanceof THREE.Mesh) {
          if (Array.isArray(object.material)) {
            object.material.forEach(material => material.dispose());
          } else {
            object.material.dispose();
          }
          object.geometry.dispose();
        }
      });

      // Dispose of camera and controls
      if (scene.userData.camera) {
        scene.userData.camera = null;
      }
      if (scene.userData.controls) {
        scene.userData.controls.dispose();
      }

      // Remove the scene from the scenes object
      delete this.scenes[identifier];
    }
  }

  public animate() {
    this.render();
    this.animationFrameId = requestAnimationFrame(() => this.animate());
  }

  public resetCamera(identifier: string) {
    const scene = this.scenes[identifier];
    if (scene) {
      const camera = scene.userData.camera;
      const controls = scene.userData.controls;
      camera.position.copy(scene.userData.initialCameraPosition);
      controls.target.copy(scene.userData.initialCameraTarget);
      controls.update();
    }
  }
}

export default GLTFRenderer;
