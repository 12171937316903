import { formatNumber, round } from '@/filters';
import { DisplayProperty } from '@/datatypes/part';

export class CountersinkInfo {
  on_request: boolean = false;
  countersink_diameter: string = '';
}

export class ThicknessInfo {
  on_request: boolean = false;
  thickness: string = '';
  is_structural_steel: boolean = false;
}

export class MaterialInfo {
  on_request: boolean = false;
  material: string = '';
  is_structural_steel: boolean = false;
}

export class ThreadInfo {
  on_request: boolean = false;
  thread_size: string = '';
}

export class Finish {
  on_request: boolean = false;
  lc_code: string = '';
  text: string = '';
}

interface PartDetails {
  rectangular_length: number;
  rectangular_width: number;
  inner_geometries: number;
  thickness: number;
}

interface OfferPartDetails {
  material: string;
  thickness_info: ThicknessInfo;
  finish: Finish;
  shipping: string;
  bends: [];
  threads: [];
  countersinks: [];
}

class OnRequestDetails {
  material_on_request: boolean = false;
  laser_on_request: boolean = false;
  finish_on_request: boolean = false;
  bending_on_request: boolean = false;
  thread_on_request: boolean = false;
  countersink_on_request: boolean = false;
  dimension_on_request: boolean = false;

  [key: string]: any;

  constructor(on_request_details?: OnRequestDetails) {
    this.material_on_request = (on_request_details && on_request_details.material_on_request) || false;
    this.laser_on_request = (on_request_details && on_request_details.laser_on_request) || false;
    this.finish_on_request = (on_request_details && on_request_details.finish_on_request) || false;
    this.bending_on_request = (on_request_details && on_request_details.bending_on_request) || false;
    this.thread_on_request = (on_request_details && on_request_details.thread_on_request) || false;
    this.countersink_on_request = (on_request_details && on_request_details.countersink_on_request) || false;
    this.dimension_on_request = (on_request_details && on_request_details.dimension_on_request) || false;
  }

  partOnRequest() {
    return (
      this.material_on_request ||
      this.laser_on_request ||
      this.finish_on_request ||
      this.bending_on_request ||
      this.thread_on_request ||
      this.countersink_on_request ||
      this.dimension_on_request
    );
  }
}

export class FileEntry {
  name: string = '';
  progress: number = 0;
  size: number = 0;
  type: string = '';
  error_during_upload: boolean = false;
  error_message: string = '';
  uuid: string = '';
}

export class CustomerPartEntry implements PartDetails, OfferPartDetails {
  [key: string]: any;
  name: string = ''; // server
  uuid: string = ''; // server
  generated_assets_uuid: string = ''; //server
  has_image: boolean = false; // server
  counts: number[] = [1, 0, 0]; // client

  is_manual = false;

  rectangular_length: number = 0;
  rectangular_width: number = 0;
  inner_geometries: number = 0;
  thickness: number = 0;

  // offer_part_detail
  material: string = '';
  thickness_info: ThicknessInfo = new ThicknessInfo();
  finish: Finish = new Finish();
  shipping: string = '';
  bends: [] = [];
  threads: [] = [];
  countersinks: [] = [];
  source_uuid: string = '';

  user_comment = '';

  price_limit_surpassed: boolean = false;
  on_request_details: OnRequestDetails = new OnRequestDetails();

  displayProperties(): DisplayProperty[] {
    return [
      {
        displayName: 'Länge',
        displayValue: formatNumber(round(this.rectangular_length, 2)),
        displaySuffix: 'mm'
      },
      {
        displayName: 'Breite',
        displayValue: formatNumber(round(this.rectangular_width, 2)),
        displaySuffix: 'mm'
      },
      {
        displayName: 'Dicke',
        displayValue: formatNumber(round(this.thickness, 2)),
        displaySuffix: 'mm'
      },
      {
        displayName: 'Innere Geometrien',
        displayValue: formatNumber(this.inner_geometries),
        displaySuffix: undefined
      }
    ];
  }

  createUpdateObject(): Object {
    return {
      uuid: this.uuid,
      counts: this.counts,
      offer_part_detail: this.createPartDetailObject()
    };
  }

  protected createPartDetailObject(): Object {
    return {
      material: this.material,
      thickness_info: this.thickness_info,
      finish: this.finish,
      shipping: this.shipping,
      bends: this.bends,
      threads: this.threads,
      countersinks: this.countersinks,
      user_comment: this.user_comment
    };
  }

  setFieldsFromDetailsObject(part_details: PartDetails): void {
    this.rectangular_length = part_details.rectangular_length;
    this.rectangular_width = part_details.rectangular_width;
    this.inner_geometries = part_details.inner_geometries;
    this.thickness = part_details.thickness;
  }

  setFieldsFromOnRequestDetailsObject(on_request_details: OnRequestDetails): void {
    this.on_request_details = new OnRequestDetails(on_request_details);
  }
}
