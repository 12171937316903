
import { BACKEND_URL } from '@/za_conf';
import Vue from 'vue';
import ImageViewer from '@/components/util/ImageViewer.vue';

export default Vue.extend({
  components: {
    zaimagevisualizer: ImageViewer
  },
  props: {
    offerSet: Object as () => any,
    name: String as () => string,
    uuid: String as () => string,
    length: Number as () => number,
    width: Number as () => number,
    onRequest: Boolean as () => boolean,
    priceLimitReached: Boolean as () => boolean,
    comment: String as () => string
  },
  // type inference enabled
  data() {
    return {
      BACKEND_URL: BACKEND_URL,
      vis_data: { uuid: this.uuid },
      expand: true,
      selection: -1,
      local_comment: this.comment,
      headers: [
        {
          text: 'Position',
          align: 'left',
          sortable: true,
          value: 'name'
        },

        { text: 'Einrichtkosten*', value: 'setup_cost' },
        { text: 'Produktionskosten*', value: 'process_cost' },
        { text: 'Risikomarge*', value: 'risk_margin' },
        { text: 'Herstellungskosten*', value: 'production_cost' },
        { text: '', value: 'action', sortable: false }
      ]
    };
  },
  computed: {
    material() {
      return this.offerSet.material;
    },
    thickness() {
      return this.offerSet.thickness.thickness;
    },
    finish() {
      return this.offerSet.finish?.text;
    },
    lasertime() {
      return this.offerSet.estimated_laser_time;
    },
    materialWeight() {
      return this.offerSet.estimated_material_weight;
    }
  },
  methods: {
    calcRoundedPricePerPart(offer: any) {
      let price_per_part = offer.angebotspreis / offer.lot_size;
      if (price_per_part <= 5) {
        // round to 5 rappen
        return (Math.ceil(price_per_part * 20) / 20).toFixed(2);
      } else {
        // round to 10 rappen
        return (Math.ceil(price_per_part * 10) / 10).toFixed(2);
      }
    },
    changeSelection(index: any) {
      (this as any).selection = index;
      (this as any).dataChanged();
    },
    dataChanged() {
      this.$emit('added-comment', this.uuid, this.local_comment);
    },
    isSelectedStyle(index: any) {
      if (index === (this as any).selection) {
        return 'yellow lighten-2';
      }
      return '';
    },
    getValidatedStyle(isValidated: boolean) {
      if (isValidated) {
        return 'light-blue';
      } else {
        return '';
      }
    }
  },
  mounted(): void {
    this.$emit('offer-selection-changed', this.uuid, (this as any).selection, (this as any).comment);
  }
});
