
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class WelcomeDialog extends Vue {
  private showDialog = false;
  private dontShowAgain = false;
  private readonly COOKIE_NAME = 'hideWelcomeDialog';

  mounted() {
    const hideDialog = this.$cookies.get(this.COOKIE_NAME);
    if (!hideDialog) {
      this.showDialog = true;
    }
  }

  closeDialog() {
    if (this.dontShowAgain) {
      this.$cookies.set(this.COOKIE_NAME, 'true', '365d');
    }
    this.showDialog = false;
  }
}
