
import { Component, Vue } from 'vue-property-decorator';
import NavigationDrawer from '@/components/NavigationDrawer.vue';

@Component({
  components: {
    navdraw: NavigationDrawer
  }
})
export default class Header extends Vue {
  drawer: boolean = false;

  get isTestVersion() {
    return process.env.VUE_APP_TEST_ENV === 'true';
  }

  logout() {
    this.$store.commit('logoutUser');
    this.$router.push('/login');
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
    setTimeout(() => {
      this.updateDrawerState(this.drawer);
    }, 300);
  }

  get username() {
    // assume implemented
    return this.$store.getters.userName;
  }

  get loggedIn() {
    return this.$store.getters.isAuthenticated;
  }

  updateDrawerState(state: boolean) {
    this.$store.commit('updateDrawerState', state);
    this.drawer = state;
  }
}
