
import { Component, Vue } from 'vue-property-decorator';
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import WelcomeDialog from '@/components/WelcomeDialog.vue';

@Component({
  components: {
    zaHeader: Header,
    zaFooter: Footer,
    welcomeDialog: WelcomeDialog
  }
})
export default class App extends Vue {
  showCookieBanner = false;
  showWelcomeDialog = false;

  mounted() {
    this.checkCookieConsent();
    this.checkWelcomeDialog();
  }

  get loggedIn() {
    return this.$store.getters.isAuthenticated;
  }

  checkCookieConsent() {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      this.showCookieBanner = true;
    }
  }

  confirmCookies() {
    localStorage.setItem('cookieConsent', 'true');
    this.showCookieBanner = false;
  }

  checkWelcomeDialog() {
    const welcomeConfirmed = localStorage.getItem('welcomeConfirmed');
    if (!welcomeConfirmed && this.loggedIn) {
      this.showWelcomeDialog = true;
    }
  }

  confirmWelcomeDialog() {
    localStorage.setItem('welcomeConfirmed', 'true');
    this.showWelcomeDialog = false;
  }
}
