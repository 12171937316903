
import { Component, Vue, Watch } from 'vue-property-decorator';
import CalcFileUpload from '../components/calculator/CalcFileUpload.vue';
import CalcProperties from '@/components/calculator/CalcProperties.vue';
import CalcOffer from '@/components/calculator/CalcOffer.vue';
import axios from 'axios';
import { BACKEND_URL } from '@/za_conf';
import CalcShipping from '@/components/calculator/CalcShipping.vue';
import CalcExportLastech from '@/components/calculator/CalcExportLastech.vue';

@Component({
  components: {
    calcfileupload: CalcFileUpload,
    calcproperties: CalcProperties,
    calcoffer: CalcOffer,
    calcshipping: CalcShipping,
    calcexport: CalcExportLastech
  }
})
export default class Calculator extends Vue {
  offer_name: string = '';
  steps = this.defineSteps();
  confirmDialog: boolean = false;
  OFFER_STEP_INDEX = 2;

  defineSteps() {
    let steps = [
      {
        name: 'Offertpositionen erfassen',
        component: 'calcfileupload',
        eventHandling: {}
      },
      {
        name: 'Eigenschaften',
        component: 'calcproperties',
        eventHandling: {}
      },
      {
        name: 'Offerte',
        component: 'calcoffer',
        eventHandling: {}
      }
    ];

    if (this.hasManufacturerRights()) {
      steps.push({
        name: 'Export',
        component: 'calcexport',
        eventHandling: {}
      });
    }

    return steps;
  }

  deleteOffer() {
    this.$store.dispatch('deleteCurrentOffer');
    this.$store.commit('resetOffer');
  }

  get currentOfferName() {
    return this.$store.getters.getFormattedOfferId + ' - ' + this.$store.getters.currentOfferName;
  }

  get currentStep() {
    return this.$store.getters.currentStepCalcDialog;
  }

  updateStep(step_number: number) {
    if (this.canNavigateToStep(step_number)) {
      this.$store.commit('setCurrentStepCalcDialog', step_number);
    } else if (step_number >= this.OFFER_STEP_INDEX) {
      // Show warning message if trying to navigate to Offerte without materials selected
    }
  }

  get isOfferActive() {
    return this.$store.getters.isOfferActive;
  }

  get hasAnyParts(): boolean {
    const parts = Object.values(this.$store.getters.uploadedParts);
    return parts.length > 0;
  }

  get allPartsHaveMaterial(): boolean {
    const parts = Object.values(this.$store.getters.uploadedParts);
    return (
      parts.length > 0 &&
      parts.every((part: any) => part.material && part.material.trim() !== '' && part.thickness_info && part.finish)
    );
  }

  createOffer() {
    axios.post(`${BACKEND_URL}/create_offer`, { offer_name: this.offer_name }).then(response => {
      if (response.status === 200) {
        if ('offer_id' in response.data) {
          this.$store.commit('setOffer', {
            offer_id: response.data.offer_id,
            offer_detail: response.data.offer_detail,
            name: this.offer_name
          });
        }
      }
    });
  }

  get canNavigateToStep(): (step: number) => boolean {
    return (step: number) => {
      if (this.hasManufacturerRights()) {
        return true;
      }
      // Allow backward navigation
      if (step < this.currentStep) {
        return true;
      }

      // Check material selection when trying to navigate to or past Offerte step
      if (step >= this.OFFER_STEP_INDEX) {
        return this.allPartsHaveMaterial;
      }

      return true;
    };
  }

  isCustomer() {
    return this.$store.getters.isCustomer;
  }

  hasManufacturerRights(): boolean {
    return this.$store.getters.hasManufacturerRights;
  }
}
